<template>
	<div class="question-block">
		<table>
			<tbody>
				<tr>
					<td data-f-bold="true"><p class="qtext" data-t="s" data-f-name="Tahoma" data-f-sz="10">
						Q{{qindex+1}} (QID: {{question.qid}}<span v-if="question.colcode && question.colcode.length > 0">, ColCode: {{question.colcode}}</span>
						<span v-if="question.trendkey && question.trendkey.length > 0">, TrendKey: {{question.trendkey}}</span>)
						<i v-if="!qIsNonDashboardType && (store.user.superUser === 1 || ['Perceptual','RegRatings','CATI'].includes(surveyob.type))" style="margin-left: 5px; font-size: 16px; cursor: pointer;" class="mdi mdi-filter-plus-outline" @click="$emit('filterby', question)" title="Create filter with question"></i>
						<i v-if="!qIsNonDashboardType && (question.qgroupkey || ['MatrixHeadRadio','MatrixSubRadio','MatrixEndRadio','MatrixHeadCheck','MatrixSubCheck','MatrixEndCheck'].includes(question.type))" style="margin-left: 5px; font-size: 16px; cursor: pointer;" class="mdi mdi-table" @click="$emit('gridtable', question)" title="Show data table for grid/matrix questions"></i>
						<i v-if="question.trendkey && question.trendkey.length > 0" style="margin-left: 5px; font-size: 16px; cursor: pointer;" class="mdi mdi-trending-up" @click="getTrendData(question)" title="Show trend data"></i>
						<i v-if="!['Text','Textarea','Number'].includes(question.type)" style="margin-left: 5px; font-size: 16px; cursor: pointer;" class="mdi mdi-content-copy" @click="tableToClipboard('qrestbl'+question.qid)" title="Copy result to clipboard"></i>
						<br/><br/>

						{{question.qtext_stripped}} (n={{qResult.sampleSize}})</p>
					</td>
				</tr>
				<tr v-if="qIsNonDashboardType"><td data-t="s" data-f-name="Tahoma" data-f-sz="10"><p>This item either has text inputs or is header text, so no results to show here.</p></td></tr>
				<tr v-if="qIsNonDashboardType && isexporting === true"><td></td></tr>
			</tbody>
		</table>

		<table v-if="!qIsNonDashboardType && qResult.results.length > 0" class="table" :id="'qrestbl'+question.qid">
			<tbody>
				<tr v-for="optres in qResult.results" :key="optres.key">
					<td class="atext" data-t="s" data-f-name="Tahoma" data-f-sz="10">{{optres.title}}</td>
					<td v-if="!hiderawvals" class="raw" data-t="n" data-f-name="Tahoma" data-f-sz="10">{{resCount(optres.key)}}</td>
					<td class="val" style="padding-left: 10px;" data-t="s" data-f-name="Tahoma" data-f-sz="10">{{resPct(optres.key)}}%</td>
					<td v-if="!isexporting"><div class="graph"><span class="graphfill" :style="'width:'+resPct(optres.key)+'%;'"></span></div></td>
				</tr>
				<tr v-if="isexporting === true"><td></td></tr>
			</tbody>
		</table>

		<BlanketOverlayWithPopup v-if="trendDataShown || fetchingTrendData" title="Trend Data" width="800" v-on:close="hideTrendData">
			<template v-slot:main>
				<i style="margin-left: 5px; font-size: 16px; cursor: pointer;" class="mdi mdi-content-copy" @click="tableToClipboard('trendtable')" title="Copy result to clipboard"></i>
				<table v-if="trendDataShown === true" class="trendtable" id="trendtable">
					<tbody>
					<tr>
						<td></td>
						<td v-for="tsurv in trendsurvs" :key="tsurv.survid">{{tsurv.survname_internal}}</td>
					</tr>
					<tr v-for="optres in qResult.results" :key="optres.key">
						<td class="atext" data-t="s" data-f-name="Tahoma" data-f-sz="10">{{optres.title}}</td>
						<td v-for="tsurv in trendsurvs" :key="tsurv.survid" class="val">{{getTrendVal(tsurv.survid, optres.key)}}</td>
					</tr>
					</tbody>
				</table>
				<div v-else><br/>Loading...</div>
			</template>
		</BlanketOverlayWithPopup>

	</div>
</template>

<script>
import {globalMixin} from "@/mixins";
import {store} from "@/store";
import BlanketOverlayWithPopup from "@/components/BlanketOverlayWithPopup.vue";

export default {
	name: 'DashPerceptualSingle',
	mixins: [globalMixin],
	props: {
		resultsdata: {
			type: Array,
			default: () => []
		},
		surveyob: {
			type: Object,
			default: () => {}
		},
		demoid: {
			type: [Number, String],
			default: 0
		},
		weighted: {
			type: Boolean,
			default: true
		},
		question: {
			type: Object,
			default: () => {}
		},
		isexporting: {
			type: Boolean,
			default: false
		},
		qindex: Number,
		totalsampleoverridepct: {
			type: String,
			default: 'answered'
		},
		hiderawvals: {
			type: Boolean,
			default: false
		},
		trenddata: {
			type: Object,
			default: () => {}
		},
		trendsurvs: {
			type: Array,
			default: () => []
		},
		forcehidetrend: {
			type: Boolean,
			default: false
		},
	},
	components: {BlanketOverlayWithPopup},
	data() {
		return {
			store: store,

			weightOverride: null,

			nonDashboardQTypes: ['HeaderText','Text','Number'],
			trendDataShown: false,
			fetchingTrendData: false,
		}
	},
	computed: {
		qResult() {
			let ret = this.resultsdata.find(item => item.qid === this.question.qid)
			if(ret) return ret
			return []
		},
		qIsNonDashboardType() {
			if(this.question) {
				if(this.nonDashboardQTypes.includes(this.question.type)) return true
			}
			return false
		},
	},
	methods: {
		resCount(optid) {
			let optres = this.qResult.results.find(item => item.key === optid)
			if(optres === null) return 0
			if(this.weightOverride === true) return Math.round(optres.sumWeights)
			else if(this.weighted === true) return Math.round(optres.sumWeights)
			if(this.weightOverride === false) return optres.count
			return optres.count
		},
		resPct(optid) {
			let optres = this.qResult.results.find(item => item.key === optid)
			if(optres === null) return 0
			if(this.totalsampleoverridepct === 'alldemo') {
				if (this.weighted === true) return optres.pctSumWeightsOfAllDemo
				return optres.pctCountOfAllDemo
			}
			if(this.totalsampleoverridepct === 'totalsurvey') {
				if (this.weighted === true) return optres.pctSumWeightsOfTotalSample
				return optres.pctCountOfTotalSample
			}
			else {
				if (this.weighted === true) return optres.pctSumWeightsOfAnswered
				return optres.pctCountOfAnswered
			}
		},
		getTrendData(question) {
			if(!question.trendkey || question.trendkey.length === 0) {
				window.alert("No data available to trend for this question.")
				return
			}
			this.fetchingTrendData = true
			this.$emit('gettrenddata', question.trendkey)
		},
		hideTrendData() {
			this.trendDataShown = false
		},
		getTrendVal(survid, optid) {
			if(this.question.trendkey === this.trenddata.trendkey) {
				let res = this.trenddata.results.find(item => item.survid === survid)
				if(res) {
					let optres = res.data.find(item => item.key === optid)
					if(optres === null) return 0
					try {
						if (this.totalsampleoverridepct === 'alldemo') {
							if (this.weighted === true) return optres.pctSumWeightsOfAllDemo
							return optres.pctCountOfAllDemo
						}
						if (this.totalsampleoverridepct === 'totalsurvey') {
							if (this.weighted === true) return optres.pctSumWeightsOfTotalSample
							return optres.pctCountOfTotalSample
						}
						else {
							if (this.weighted === true) return optres.pctSumWeightsOfAnswered
							return optres.pctCountOfAnswered
						}
					} catch(e) {
						return null
					}
				}
			}
			return null
		},
	},
	watch: {
		trenddata: {
			deep: true,
			handler() {
				if (this.trenddata.trendkey && this.trenddata.trendkey.length > 0) {
					if(this.question.trendkey === this.trenddata.trendkey) {
						if (this.trenddata.results && this.trenddata.results.length > 0 && this.trendsurvs.length === this.trenddata.results.length) {
							this.trendDataShown = true
							this.fetchingTrendData = false
						}
						else this.trendDataShown = false
					}
					else this.trendDataShown = false
				}
				else this.trendDataShown = false
			}
		},
		forcehidetrend() {
			if(this.forcehidetrend === true) {
				this.trendDataShown = false
			}
		}
	},
	mounted() {

	},
	created() {

	},
}
</script>

<style scoped>
.qtext {
	text-align: left;
	font-family: Roboto,'Arial';
	font-weight: bold;
	margin-bottom: 15px;
}
.question-block {
	padding: 20px 0;
}
.table {
	padding: 5px;
	margin-bottom: 20px;
	border-collapse: collapse;
	border-spacing: 0;
}
.table tr {
	padding: 5px;
	margin: 5px;
}
.table .atext, .table .raw, .table .val {
	padding: 0 5px 0px;
	vertical-align: middle;
 }
.table .raw, .table .val {
	text-align: right;
}
.table td {
	padding: 1px 5px 1px;
	border-bottom: 1px solid #DDD;
	border-top: 1px solid #DDD;
	font-size: 13px;
}
.atext {
	width: 300px;
	text-align: left;
}
.table .graph {
	width: 400px;
	padding: 0;
	background-color: #E8E8E8;
	margin-bottom: 5px;
	margin-top: 4px;
}
.graphfill {
	width: 0px;
	height: 10px;
	vertical-align: middle;
	display: block;
	background-color: #23A6EE;
	margin: 0 0 0 0;
	padding: 3px 0;
}
.trendtable {
	padding: 5px;
	margin-bottom: 20px;
	border-collapse: collapse;
	border-spacing: 0;
}
.trendtable td {
	padding: 5px 5px;
	border: 1px solid #DDD;
	font-size: 13px;
}
.trendtable td.val {
	text-align: right;
}
</style>
